

.react-datepicker__navigation-icon {
  top:7px !important;
}

.react-datepicker__triangle {
  display:none;
}

.react-datepicker__day {
  font-weight:bold;
}
.react-datepicker__current-month {
  color:#4E27A1 !important;
}

.react-datepicker__day--selected {
  background-color:#4E27A1 !important;
}
.react-datepicker__header, .react-datepicker__month-container, .react-datepicker {
  border:0 !important;
}

.react-datepicker__header {
  background:none !important;
}

.react-datepicker {
  border-radius:10px !important;
}