.rct-text {

  margin-bottom: 8px;
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  height: 32px;
}

.rct-node label .rct-title {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 34px;
  right: 0;
  bottom: 0;
  padding-top: 4px;
  background: rgba(225, 214, 247, 0.5);
  border-radius: 5px;
}

.rct-collapse-btn {
  z-index: 2;
  outline: none !important;
}

.rct-checkbox {
  display: none;
}

span[aria-checked="true"] + .rct-title {
  font-weight: bold;
  background: #E1D6F7 !important;
}

.price-cell {
  position:relative;z-index:3;
}



.price-line {
  position:relative;
}

.article-row {
  position:relative;cursor:default;
}
.article-row:hover:after {
  position:absolute;left:0;top:0;right:0;bottom:0;background:#E1D6F7;content:"";z-index:2;opacity:0.5;
}

.price-line:after {
  position:absolute;top:0;border-top:1px solid #E1D6F7;left:0;right:0;content:"";z-index:3;
}

th.bg-alternating-column:before {

  content: "";
  background-color: black;
  opacity: 0.02;
  z-index: -1;
  position:absolute;left:0;right:0;top:0;bottom:0;
}

th.bg-alternating-column:after {

  content: "";
  background-color: #f1edfb;
  z-index: -2;
  position:absolute;left:0;right:0;top:0;bottom:-1000px;
}