.drag-symbol {
  position: absolute;
  left: -11px;
  top: 6px;
  display: none;
}

.specification-article:hover {
  .drag-symbol {
    display: block;
  }
}