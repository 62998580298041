.spreadsheet .cell {
  width: 200px;
  height: 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid black;
  border-left: 1px solid black;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  line-height: 1;

}

.spreadsheet .row-found {
  left: 0;
  right: 0;
  height: 30px;

  &:after {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: greenyellow !important;
    content: "";
    z-index: 0;
  }
}

.sticky .cell {
  border-bottom: 1px solid black;
}


.spreadsheet {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;

}

.spreadsheet:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-image: linear-gradient(90deg, #000 1px, transparent 1px);
  z-index: 1;
  background-size: 200px 30px;

}


.spreadsheet:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-image: linear-gradient(180deg, #000 1px, transparent 1px);
  z-index: 1;
  background-size: 200px 30px;
}

.spreadsheet .cell:hover, .spreadsheet .cell.active {
  background-color: rosybrown !important;
}

.spreadsheet .cell.mapped {
  background-color: lightgreen !important;
}

.spreadsheet-holder {
  width: 70vw;
}


.article-add {
  background-color: lightblue !important;
}



.sticky div {
  background-color:white !important;
}
